import { useContext, useRef } from "react";
import axios from "axios";

import { UserContext } from "../../globalContext/user/userProvider";
import useAxiosInstance from "../../Axios/hooks/useAxiosInstance";
import useLogoutUser from "../../utils/apiService/Hooks/useLogoutUser";
import {
  setErrorGettingUserInfo,
  setGettingUserData,
  setUserData,
} from "../../globalContext/user/userActions";
import { SOMETHING_WENT_WRONG } from "../../consts/errorMessages";
import { ADMIN_PROFILE, DENTIST_PROFILE, DENTIST_SUBSCRIPTION } from "../apiEndpoints";
import { AuthContext } from "../../globalContext/auth/authProvider";
import getRole from "../../auth/getRole";
import { Roles } from "../../consts/constants";
import getToken from "../../auth/getToken";

const useGettingUserInfo = () => {
  const [, userDispatch] = useContext(UserContext);
  const cancelTokenRef = useRef(null);
  const { axiosInstance } = useAxiosInstance();
  const { logoutHandler } = useLogoutUser();
  const [authState] = useContext(AuthContext);

  const getUserDetails = async () => {
    try {
      const cancelGetRequest = axios.CancelToken.source();
      cancelTokenRef.current = cancelGetRequest;
      userDispatch(setGettingUserData(true));
      const role = getRole();
      const authToken = getToken();
      const res = await axiosInstance.get(
        `${role === Roles.dentist ? DENTIST_PROFILE : ADMIN_PROFILE}/${
          authState?.id
        }`,
        {
          cancelToken: cancelGetRequest.token,
          headers: {
            platformType: "admin",
          },
        }
      );
      if(role === Roles.dentist){
      const {data:subscriptionData}=  await axiosInstance.get(DENTIST_SUBSCRIPTION)
        userDispatch(setGettingUserData(false));
        userDispatch(setUserData({...res, ...subscriptionData?.data}));
        return;
      }
   
      userDispatch(setGettingUserData(false));
      userDispatch(setUserData(res));
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || err.message || SOMETHING_WENT_WRONG;

      if (errorMessage === "Component unmounted" || err.response?.status===401) {
        return;
      }
      userDispatch(setErrorGettingUserInfo(errorMessage));
      logoutHandler({});
    } finally {
      userDispatch(setGettingUserData(false));
    }
  };

  return { cancelTokenRef, getUserDetails };
};

export default useGettingUserInfo;
