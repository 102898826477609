const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2)); // Ensure safe rotation area

  // Set canvas size to accommodate the safe area
  canvas.width = safeArea;
  canvas.height = safeArea;

  // Fill canvas with a transparent or white background based on the format
  const isPNG = imageSrc.endsWith(".png");
  ctx.fillStyle = isPNG ? "transparent" : "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Translate to the center, rotate, and translate back
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // Draw the rotated image
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  // Extract the cropped image
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // Resize canvas to final crop dimensions
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Apply crop offsets
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // Return the cropped image as a Blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (file) => {
        if (!file) return null;

        // Set the file name dynamically
        file.name = isPNG ? "cropped.png" : "cropped.jpeg";
        resolve({ file, url: URL.createObjectURL(file) });
      },
      isPNG ? "image/png" : "image/jpeg"
    );
  });
}
