import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import useLogoutUser from "../../utils/apiService/Hooks/useLogoutUser";
import Avatar from "@mui/material/Avatar";
import profile from "../../img/userProfile.png";
import rightArrow from "../../img/arrowRight.svg";
import logoutIcon from "../../img/logoutIcon.png";
import passwordChangeIcon from "../../img/changePassword.png";
import menuIcon from "../../img/MenuIcon.png";
import { styles } from "./UserMenuStyles";
import { useNavigate } from "react-router-dom";
import navigations from "../../routes/routeNames";

const UserMenu = ({ userInfo }) => {
  const { logoutHandler } = useLogoutUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuOptions = [
    {
      id: 3,
      name: "My Subscription",
      hasRightArrow: true,
      icon: profile,
      onClick: () => {
        handleClose();
        navigate(`${navigations.DENTIST_SETTING}?tab=${"my-subscriptions"}`);
      },
    },
    {
      id: 4,
      name: "Change password",
      hasRightArrow: true,
      icon: passwordChangeIcon,
      onClick: () => {
        handleClose();
        navigate(`${navigations.DENTIST_SETTING}?tab=${"change-password"}`);
      },
    },
    {
      id: 5,
      name: "Logout",
      icon: logoutIcon,
      onClick: () => {
        handleClose();
        logoutHandler({});
      },
    },
  ];

  const getAvatar = (onClick) => {
    const userImage = userInfo?.data?.data?.image;
    const firstLetter = userInfo?.data?.data?.first_name
      ?.charAt(0)
      .toUpperCase();

    return (
      <Box sx={styles.avtarBox}>
        {userImage ? (
          <Avatar sx={styles.avatarStyle} alt={firstLetter} src={userImage} />
        ) : (
          <Avatar sx={styles.avatarStyle}>{firstLetter}</Avatar>
        )}
      </Box>
    );
  };

  return (
    <Box sx={styles.avtarBox}>
      {getAvatar(handleClick)}
      <IconButton
        onClick={handleClick}
        aria-controls={Boolean(anchorEl) ? "user-menu" : undefined}
        aria-haspopup="true"
      >
        <Typography
          component={"img"}
          src={menuIcon}
          alt="Menu Icon"
          style={styles.menuIconLogo}
        />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={styles.menuStyle}
      >
        <MenuItem sx={styles.userDescription}>
          {getAvatar(handleClose)}
          <Box sx={styles.userDetail}>
            <Typography sx={styles.nameText}>
              {userInfo?.data?.data?.first_name.charAt(0).toUpperCase() +
                userInfo?.data?.data?.first_name.slice(1).toLowerCase()}{" "}
              {userInfo?.data?.data?.last_name.charAt(0).toUpperCase() +
                userInfo?.data?.data?.last_name.slice(1).toLowerCase()}
            </Typography>
            <Typography sx={styles.emailText}>
              {userInfo?.data?.data?.email}
            </Typography>
          </Box>
        </MenuItem>

        <Divider sx={styles.dividerStyles} />
        {menuOptions.map((item) => (
          <MenuItem
            onClick={item.onClick}
            sx={styles.itemContainer}
            key={item.id}
          >
            <Box sx={styles.itemSubContainer}>
              {item.icon && (
                <Typography
                  component="img"
                  src={item.icon}
                  sx={styles.itemActionIcon}
                />
              )}
              <Typography sx={styles.actionText}>{item.name}</Typography>
            </Box>
            {item.hasRightArrow && (
              <Typography
                component="img"
                src={rightArrow}
                sx={styles.itemActionIcon}
              />
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default UserMenu;
