import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography} from "@mui/material";

import CustomMultiCarousel from "../CustomMultiCarousel/CustomMultiCarousel";
import ZoomableImage from "../ZoomableImage/ZoomableImage";
import { styles } from "./UtilityModalStyles";
import VideoPlayerHsl from "../VideoPlayerHsl/VideoPlayerHsl";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

export default function UtilityModal({
  openModal,
  setCloseModal,
  photos,
  selectedPhotoIndex,
  isSinglePicture,
  singleImageSource,
  video,
  setSelectedVideo
}) {
  const [open, setOpen] = useState(openModal);

  const handleClose = () => {
    setOpen(false);
   setSelectedVideo && setSelectedVideo(null);
    setCloseModal(false);
  };

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp, true);
    return () => {
      window.removeEventListener("keyup", handleKeyUp, true);
    };

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyUp = (e) => {
    const keys = {
      27: () => {
        e.preventDefault();
        handleClose();
      },
    };
    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
    e.stopPropagation();
  };

  const isVideoMp4= video?.trim()?.endsWith(".mp4");
 
  return (
    <>
      {open && (
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEscapeKeyDown={true}
        >
          <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'transparent',
              width:"auto",
              minWidth:"50vw"
          }}>


<Box sx={{display:"flex",flexDirection:"row",justifyContent:"flex-end"}}>

<IconButton
      onClick={handleClose}
      sx={{
        ...styles.closeIconButton,
        ...(!isVideoMp4 && !video ? { marginRight: "50px" } : {marginRight:"-16px"})
      }}
    >
      <CloseIcon sx={styles.closeButton} />
    </IconButton>
            </Box>

            {isSinglePicture ? (
              <ZoomableImage
                src={singleImageSource}
                alt="image"
                includeZoomControls
                handleClose={handleClose}
              />
            ) : (video && !isVideoMp4) ? (
              <VideoPlayerHsl
                customVideoStyles={styles.videoStyle}
                src={video}
              />
            ) :isVideoMp4?
            (
              <VideoPlayer
              src={video}
              customVideoStyles={styles.videoStyle}

              />
            )    
            : (
              <CustomMultiCarousel
                photos={photos}
                size="large"
                preventOnImageClick
                defaultSelectedPhotoPosition={selectedPhotoIndex}
                isZoomable
                handleClose={handleClose}
              />
            )}
          </Box>
          {/* <Box sx={styles.modalBox}>
          
          </Box> */}
        </Modal>
      )}
    </>
  );
}

UtilityModal.propTypes = {
  openModal: PropTypes.bool,
  setCloseModal: PropTypes.func,
  photos: PropTypes.array,
  selectedPhotoIndex: PropTypes.number,
  isSinglePicture: PropTypes.bool,
  singleImageSource: PropTypes.string,
};
