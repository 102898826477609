import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import PropTypes from "prop-types";
import {  Typography } from "@mui/material";

import ZoomableImageControls from "./ZoomableImageControls";
import { styles } from "./ZoomableImageStyles";

const ZoomableImage = ({
  alt,
  customImageStyles,
  includeZoomControls,
  placeControlsOnTopRight,
  src,
}) => {
  return (
    <TransformWrapper>
      {includeZoomControls && (
        <ZoomableImageControls
          placeControlsOnTopRight={placeControlsOnTopRight}
        />
      )}
      <TransformComponent>
        <Typography
          component="img"
          sx={{ ...styles.imageStyles, ...customImageStyles }}
          {...{ alt, src }}
        />
      </TransformComponent>
    </TransformWrapper>
  );
};

ZoomableImage.propTypes = {
  alt: PropTypes.string,
  customImageStyles: PropTypes.object,
  includeZoomControls: PropTypes.bool,
  placeControlsOnTopRight: PropTypes.bool,
  src: PropTypes.string,
};

export default ZoomableImage;
