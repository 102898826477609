import {
    DISABLE_GREY,
    GREY98,
    WHITE,
    BERN_RED,
    BUTTON_COLOR,
  } from "../../consts/colors";
  
  export const styles = {
    buttonStyles: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      backgroundColor: BUTTON_COLOR,
      color: GREY98,
      height: "40px",
      width: "90%",
      textTransform: "capitalize",
      borderRadius: "8px",
     boxShadow: "0 8px 16px 0 rgba(0,0,0,0.15), 0 6px 20px 0 rgba(0,0,0,0.14)",
      "&:hover": {
        backgroundColor: BUTTON_COLOR,
        boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.18)", 
      },
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    disabledButton: {
      backgroundColor: DISABLE_GREY,
      "&:hover": {
        backgroundColor: DISABLE_GREY,
      },
    },
    btnLoaderStyles: {
      marginLeft: "0.5rem",
      color: WHITE,
    },
    dangerButton: {
      backgroundColor: BERN_RED,
      "&:hover": {
        backgroundColor: BERN_RED,
      },
    },
  };
  